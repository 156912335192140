import React, { useEffect, useState } from 'react'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import { useRecoilState } from 'recoil'
import { activeMenuState } from 'src/recoil/atoms'
import { Flex, Box } from 'reflexbox'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import { TikTokIcon, InstagramIcon } from 'src/components/UI/Icon/Icon'
import classnames from 'classnames'

import * as styles from './Menu.module.scss'

const Menu = ({ links }) => {
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    setShowMenu(true)
  }, [])

  if (!showMenu) {
    return null
  }

  return <Child links={links} />
}

const Child = ({ links }) => {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)

  return (
    <React.Fragment>
      <Container
        className={
          activeMenu === 'nav'
            ? [styles.wrapper, styles.active].join(' ')
            : styles.wrapper
        }
        paddingBottom={12}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={styles.header}
        ></Flex>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          paddingTop={[14]}
        >
          <Flex flexDirection="column" className={styles.navigation} as="nav">
            {links &&
              links.length > 0 &&
              links.map((link) => {
                const { url, title } = link ? getLinkAttributes(link) : {}

                return (
                  <Box key={link.uid} marginBottom={[4, null, null, 4]}>
                    <LinkComponent
                      to={`/${url}`}
                      onClick={() => setActiveMenu('')}
                    >
                      {title}
                    </LinkComponent>
                  </Box>
                )
              })}
          </Flex>
          <Flex alignItems="center">
            <Flex marginRight={3} alignItems="center">
              <LinkComponent
                to="https://www.instagram.com/grammisfryshuset/"
                className={styles.iconLink}
              >
                <InstagramIcon className={styles.icon} color="white" />
              </LinkComponent>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <LinkComponent
                to="https://www.tiktok.com/@youplus_official?is_from_webapp=1&sender_device=pc"
                className={classnames(styles.iconLink, styles.iconBig)}
              >
                <TikTokIcon className={styles.icon} color="white" />
              </LinkComponent>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </React.Fragment>
  )
}

export default Menu
