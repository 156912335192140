import React from 'react'
import SbEditable from 'storyblok-react'
import { useRecoilState } from 'recoil'
import { activeMenuState } from 'src/recoil/atoms'
import { Container } from 'src/components/UI/Grid/Grid'
import { Flex, Box } from 'reflexbox'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import classnames from 'classnames'
import {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
} from 'src/components/UI/Icon/Icon'

import { Props } from './types'
import * as styles from './Header.module.scss'

const Header = ({ blok }: Props): JSX.Element => {
  const { links, ticket_link } = blok || {}
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)

  return (
    <SbEditable content={blok}>
      <Flex as="header" className={styles.wrapper}>
        <Container className={styles.header} width={1}>
          <Flex
            width={1}
            height="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex
              as="button"
              onClick={() => setActiveMenu(activeMenu === 'nav' ? '' : 'nav')}
              className={classnames(styles.icon, 'hideDesktop')}
            >
              {activeMenu === 'nav' ? 'Stäng' : 'Meny'}
            </Flex>
            <Flex
              as="nav"
              width={1}
              className={classnames(styles.links, 'hideMobile')}
            >
              {links &&
                links.length > 0 &&
                links.map((link) => {
                  const { url, title } = link ? getLinkAttributes(link) : {}

                  return (
                    <Box key={link.uid} marginRight={10}>
                      <LinkComponent to={url}>{title}</LinkComponent>
                    </Box>
                  )
                })}
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              height="100%"
              flexShrink="0"
            >
              <Flex alignItems="center">
                <LinkComponent
                  to="https://www.instagram.com/youplus_grammisfryshuset/"
                  className={styles.iconLink}
                >
                  <InstagramIcon className={styles.icon} color="white" />
                </LinkComponent>
              </Flex>
              <Flex marginLeft={5} alignItems="center">
                <LinkComponent
                  to="https://www.tiktok.com/@youplus_official?is_from_webapp=1&sender_device=pc"
                  className={classnames(styles.iconLink, styles.iconBig)}
                >
                  <TikTokIcon className={styles.icon} color="white" />
                </LinkComponent>
              </Flex>
              {ticket_link &&
                ticket_link.length > 0 &&
                ticket_link.map((link) => {
                  const { url, title } = link ? getLinkAttributes(link) : {}
                  return (
                    <Box key={link.uid} marginLeft={10}>
                      <LinkComponent className={styles.button} to={url}>
                        <Box as="span">{title}</Box>
                      </LinkComponent>
                    </Box>
                  )
                })}
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </SbEditable>
  )
}

export default Header
