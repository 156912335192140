import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import SbEditable from 'storyblok-react'
import Markdown from 'src/components/Markdown/Markdown'
import {
  LogotypeSami,
  LogotypeFryshuset,
  LogotypeGrammis,
  LogotypeLiveNation,
  LogotypeSpotify,
  LogotypeLuger,
  LogotypeUniversal,
  LogotypeWarner,
  LogotypeSony,
  LogotypeMMF,
  LogotypeStim,
} from 'src/components/UI/Logotype/Logotype'
import classnames from 'classnames'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './Footer.module.scss'
import { Props } from './types'

const Footer = ({ blok }: Props): JSX.Element => {
  const { contact_title, contact, newsletter_title, newsletter_text } =
    blok || {}

  return (
    <SbEditable content={blok}>
      <Container
        marginTop="auto"
        as="footer"
        className={styles.wrapper}
        paddingY={[10, null, null, '6vw']}
        bg="black"
      >
        <Box marginBottom={[10, null, null, 10]}>
          {/* <Row>
            <Column width={6 / 12}>
              <Box
                className={classnames(styles.label, styles.divider)}
                marginBottom={[0, null, null, 0]}
              >
                Presentatör
              </Box>
            </Column>
          </Row>
          <div className={styles.logoGrid}>
            <div className={styles.item}>
              <LinkComponent
                className={styles.itemLink}
                to="https://fryshuset.se/"
              >
                <LogotypeFryshuset color="#FFF" />
              </LinkComponent>
            </div>
            <div className={styles.item}>
              <LinkComponent
                className={styles.itemLink}
                to="https://grammis.se"
              >
                <LogotypeGrammis color="#FFF" />
              </LinkComponent>
            </div>
          </div>
          <Row>
            <Column width={6 / 12}>
              <Box
                className={classnames(styles.label, styles.divider)}
                marginBottom={[0, null, null, 0]}
              >
                Huvudpartner
              </Box>
            </Column>
          </Row>
          <div className={styles.logoGrid}>
            <div className={styles.item}>
              <LinkComponent
                className={styles.itemLink}
                to="https://www.livenation.se/"
              >
                <LogotypeLiveNation color="#FFF" />
              </LinkComponent>
            </div>
            <div className={styles.item}>
              <LinkComponent
                className={styles.itemLink}
                to="https://grammis.se"
              >
                <LogotypeGrammis color="#FFF" />
              </LinkComponent>
            </div>
          </div> */}
          <Row>
            <Column
              width={[1, null, null, 5 / 8]}
              marginBottom={[4, null, null, 4]}
            >
              <Box marginBottom={[8, null, null, 8]}>
                <Flex>
                  <Box
                    className={classnames(styles.label, styles.divider)}
                    marginBottom={[0, null, null, 0]}
                  >
                    Presentatör
                  </Box>
                </Flex>
                <Flex flexWrap="wrap">
                  <Flex
                    className={styles.sponsorBlock}
                    width={[1 / 3, null, null, 1 / 5]}
                  >
                    <LinkComponent
                      className={styles.sponsor}
                      to="https://fryshuset.se/"
                    >
                      <LogotypeFryshuset
                        className={classnames(
                          styles.logotype,
                          styles.fryshuset
                        )}
                        color="#FFF"
                      />
                    </LinkComponent>
                  </Flex>

                  <Flex
                    className={styles.sponsorBlock}
                    width={[1 / 3, null, null, 1 / 5]}
                  >
                    <LinkComponent
                      className={styles.sponsor}
                      to="https://grammis.se"
                    >
                      <LogotypeGrammis
                        className={classnames(styles.logotype, styles.grammis)}
                        color="#FFF"
                      />
                    </LinkComponent>
                  </Flex>
                  <Flex
                    className={styles.sponsorBlock}
                    width={[1 / 3, null, null, 1 / 5]}
                  >
                    <LinkComponent
                      className={styles.sponsor}
                      to="https://www.livenation.se/"
                    >
                      <LogotypeLiveNation
                        className={classnames(
                          styles.logotype,
                          styles.livenation
                        )}
                        color="#FFF"
                      />
                    </LinkComponent>
                  </Flex>
                  <Flex
                    className={styles.sponsorBlock}
                    width={[1 / 3, null, null, 1 / 5]}
                  >
                    <LinkComponent
                      className={styles.sponsor}
                      to="https://www.spotify.com/se/"
                    >
                      <LogotypeSpotify
                        className={classnames(styles.logotype, styles.spotify)}
                        color="#FFF"
                      />
                    </LinkComponent>
                  </Flex>
                  <Flex
                    className={styles.sponsorBlock}
                    width={[1 / 3, null, null, 1 / 5]}
                  >
                    <LinkComponent
                      className={styles.sponsor}
                      to="https://www.timberglingfoundation.org/"
                    >
                      <img
                        src="/logotypes/tbf.png"
                        alt="Tim Bergling Foundation"
                      />
                    </LinkComponent>
                  </Flex>
                </Flex>
              </Box>
            </Column>

            <Column width={[1, null, null, 2 / 8]}>
              <Box>
                <Flex>
                  <Box
                    className={classnames(styles.label, styles.divider)}
                    marginBottom={[1, null, null, 2]}
                  >
                    Partner
                  </Box>
                </Flex>
                <Flex flexWrap="wrap">
                  <Flex
                    className={styles.sponsorBlock}
                    width={[1 / 3, null, null, 1 / 2]}
                  >
                    <LinkComponent
                      className={styles.sponsor}
                      to="https://www.stim.se"
                    >
                      <img src="/logotypes/stim-white.png" alt="Stim" />
                    </LinkComponent>
                  </Flex>
                </Flex>
              </Box>
            </Column>

            <Column width={[1, null, null, 8 / 8]}>
              <Flex>
                <Box
                  className={classnames(styles.label, styles.divider)}
                  marginBottom={[1, null, null, 2]}
                >
                  Supporter
                </Box>
              </Flex>
              <Flex flexWrap="wrap">
                <Flex
                  className={styles.sponsorBlock}
                  width={[1 / 3, null, null, 1 / 8]}
                >
                  <LinkComponent
                    className={styles.sponsor}
                    to="https://www.luger.se/"
                  >
                    <LogotypeLuger
                      className={classnames(styles.logotype, styles.luger)}
                      color="#FFF"
                    />
                  </LinkComponent>
                </Flex>

                <Flex
                  className={styles.sponsorBlock}
                  width={[1 / 3, null, null, 1 / 8]}
                >
                  <LinkComponent
                    className={styles.sponsor}
                    to="https://sami.se/"
                  >
                    <img src="/logotypes/sami.png" alt="Sami" />
                  </LinkComponent>
                </Flex>
                <Flex
                  className={styles.sponsorBlock}
                  width={[1 / 3, null, null, 1 / 8]}
                >
                  <LinkComponent
                    className={styles.sponsor}
                    to="https://www.sonymusic.se/"
                  >
                    <LogotypeSony
                      className={classnames(styles.logotype, styles.sony)}
                      color="#FFF"
                    />
                  </LinkComponent>
                </Flex>
                <Flex
                  className={styles.sponsorBlock}
                  width={[1 / 3, null, null, 1 / 8]}
                >
                  <LinkComponent
                    className={styles.sponsor}
                    to="https://sonymusic.se/"
                  >
                    <img src="/logotypes/sony.png" alt="Sony" />
                  </LinkComponent>
                </Flex>
                <Flex
                  className={styles.sponsorBlock}
                  width={[1 / 3, null, null, 1 / 8]}
                >
                  <LinkComponent className={styles.sponsor} to="https://sr.se/">
                    <img src="/logotypes/sverigesradio-white.png" alt="SR" />
                  </LinkComponent>
                </Flex>
                <Flex
                  className={styles.sponsorBlock}
                  width={[1 / 3, null, null, 1 / 8]}
                >
                  <LinkComponent
                    className={styles.sponsor}
                    to="https://www.umusicpub.com/"
                  >
                    <img src="/logotypes/ump.png" alt="Sony" />
                  </LinkComponent>
                </Flex>
                <Flex
                  className={styles.sponsorBlock}
                  width={[1 / 3, null, null, 1 / 8]}
                >
                  <LinkComponent
                    className={styles.sponsor}
                    to="https://universalmusic.se"
                  >
                    <LogotypeUniversal
                      className={classnames(styles.logotype, styles.universal)}
                      color="#FFF"
                    />
                  </LinkComponent>
                </Flex>
                <Flex
                  className={styles.sponsorBlock}
                  width={[1 / 3, null, null, 1 / 8]}
                >
                  <LinkComponent
                    className={styles.sponsor}
                    to="https://www.warnermusic.se/"
                  >
                    <LogotypeWarner
                      className={classnames(styles.logotype, styles.warner)}
                      color="#FFF"
                    />
                  </LinkComponent>
                </Flex>
              </Flex>
            </Column>
          </Row>
        </Box>
        <Row>
          <Column width={[1, null, null, 2 / 6]}>
            <Box marginBottom={[10, null, null, 0]}>
              {contact_title && (
                <Box className={styles.label} marginBottom={[2, null, null, 4]}>
                  {contact_title}
                </Box>
              )}
              {contact && <Markdown>{contact}</Markdown>}
            </Box>
          </Column>
          {/* <Column width={[1, null, null, 4 / 6]}>
            {newsletter_title && (
              <Box className={styles.label} marginBottom={[2, null, null, 4]}>
                {newsletter_title}
              </Box>
            )}
            {newsletter_text && (
              <Box marginBottom={[4, null, null, 0]}>
                <Markdown>{newsletter_text}</Markdown>
              </Box>
            )}
            <Box marginTop={[0, null, null, 4]}>
              <MailchimpForm url={list} />
            </Box>
          </Column> */}
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Footer
